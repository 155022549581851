<template>
  <div>
    <!-- Type -->
    <div class="card">
      <div class="card-body">
        <CreateAutoTemplateForm ref="autoform" v-if="isAutoTemplate" />
        <CreateTemplateForm ref="form" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplateForm from './CreateTemplateForm'
import CreateAutoTemplateForm from './CreateAutoTemplateForm'
import { createNamespacedHelpers } from 'vuex'

const TemplateModule = createNamespacedHelpers('template')

export default {
  name: 'CreateTemplateWrapper',
  components: {
    CreateTemplateForm,
    CreateAutoTemplateForm,
  },
  computed: {
    ...TemplateModule.mapGetters(['template', 'selectAutoTemplate']),
    isAutoTemplate() {
      return this.selectAutoTemplate._id
    },
  },
}
</script>
