<template>
  <MainContent>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <CreateTemplateHeader />
        </div>
      </div>
      <div>
        <CreateTemplateWrapper />
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CreateTemplateHeader from '@/components/Modules/Template/CreateTemplate/CreateTemplateHeader'
import CreateTemplateWrapper from '@/components/Modules/Template/CreateTemplate/CreateTemplateWrapper'

export default {
  name: 'CreateTemplatePage',
  components: {
    MainContent,
    CreateTemplateHeader,
    CreateTemplateWrapper,
  },
}
</script>

<style scoped lang="scss"></style>
