<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="header-pretitle">Winback</h6>
          <h1 class="header-title">{{ title }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const TemplateModule = createNamespacedHelpers('template')

export default {
  name: 'CreateTemplateHeader',
  computed: {
    ...TemplateModule.mapGetters(['template', 'selectAutoTemplate']),
    isEditing() {
      return this.selectAutoTemplate._id || this.template._id
    },
    isAutoTemplate() {
      return this.selectAutoTemplate._id
    },
    title() {
      let title = ''
      title += this.isEditing ? 'Edit' : 'Create'
      title += ' '
      title += this.isAutoTemplate ? 'Auto Template' : 'Template'
      return title
    },
  },
}
</script>

<style scoped lang="scss"></style>
